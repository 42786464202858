import logo from './logoNoShadow.svg'
import Nav from './Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCoins, faDonate } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import Footer from './Footer'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './globalStyles'
import { lightTheme, darkTheme } from './Themes'
import { useState } from 'react'
import './App.css'

function getStats() {
  return [
    { icon: faLock, stat: '$1B', subtext: 'Total Value Locked' },
    { icon: faCoins, stat: '$3.64B', subtext: 'Total Volume Traded' },
    { icon: faDonate, stat: '$159M', subtext: '$NRV Market Cap' },
  ]
}

function App() {
  const [theme, setTheme] = useState('dark')
  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light')
  }
  const statOptions = getStats()
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <div className='nerve-app__main-container'>
          <motion.div
            initial={{ y: '-10vh' }}
            animate={{ y: '0vh' }}
            transition={{ type: 'ease-in', duration: 0.5 }}
          >
            <Nav toggleDarkMode={themeToggler} theme={theme} />
          </motion.div>
          <div className='nerve-app__body'>
            <motion.div
              className='nerve-app__content-container'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div>
                <h1 className='nerve-app__main-header'>
                  A trustless on-ramp and stableswap on Binance Smart Chain
                </h1>
                <h2>
                  Bridge assets onto BSC and earn yield on BTC, ETH, and
                  stablecoins
                </h2>
                <div className='nerve-app__button-wrapper'>
                  {' '}
                  <a href='https://app.nerve.fi'>
                    <button className='nerve-app__content-button'>
                      Launch App
                    </button>
                  </a>
                  <a
                    href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x42f6f551ae042cbe50c739158b4f0cac0edb9096&inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56'
                    target='_blank'
                  >
                    <button className='nerve-app__content-button' id='trade'>
                      Trade $NRV
                    </button>
                  </a>
                </div>
              </div>

              <img src={logo} className='nerve-app__hide-element' />
            </motion.div>
            <motion.ul
              className='nerve-app__stats-container'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              {statOptions.map((option) => (
                <li>
                  <FontAwesomeIcon
                    icon={option.icon}
                    className='nerve-app__stats-icon'
                  />
                  <div className='nerve-app__stats-right'>
                    <h1>{option.stat}</h1>
                    <span>{option.subtext}</span>
                  </div>
                </li>
              ))}
            </motion.ul>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <Footer />
          </motion.div>
        </div>
      </>
    </ThemeProvider>
  )
}

export default App
